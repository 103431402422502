//SubscriptionPage.js
import React, { useState, useEffect, useCallback } from "react";
import Header from "./common/Header";
import PaypalButton from "./PaypalButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdCheckCircleOutline } from "react-icons/md";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Card = ({
  plan,
  title,
  price,
  originalPrice,
  features,
  selectedPlan,
  onSelectPlan,
}) => {
  const isSelected = selectedPlan === plan;
  const cardClass = isSelected
    ? "border-blue-500 bg-gray-800"
    : "border-gray-300 hover:bg-[#2a2a2a]";

  return (
    <div
      className={`flex-1 cursor-pointer border ${cardClass} rounded-lg p-4 mb-3`}
      onClick={() => onSelectPlan(plan)}
    >
      <div className="flex flex-col">
        <p className="text-2xl mb-3">{title}</p>

        <div className="flex items-end space-x-1">
          <p className="text-3xl">{price}</p>
          <p className="text-2xl text-[#aaaaaa] line-through">
            {originalPrice}
          </p>
        </div>
        {/* 特性列表 */}
        <div>
          {features.map((feature, index) => (
            <div key={index} className="flex items-center mt-4">
              <MdCheckCircleOutline className="text-green-500 h-[20px] w-[20px] mr-2" />
              <span className="w-[150px]">{feature}</span> {/* 设置固定宽度 */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const DonationCard = ({
  selectedPlan,
  onSelectPlan,
  donationAmount,
  onDonationAmountChange,
  handleIncrement,
  handleDecrement,
}) => {
  const isSelected = selectedPlan === "free";
  const cardClass = isSelected
    ? "border-blue-500 bg-gray-800 h-[400px]"
    : "border-gray-300 hover:bg-[#2a2a2a]"; // 未选中状态样式: 悬停时深色背景
  const { t } = useTranslation();
  return (
    <div
      className={`flex-1 justify-between cursor-pointer border ${cardClass} rounded-lg p-4 mb-3 flex flex-col items-center`}
      onClick={() => onSelectPlan("free")}
    >
      <p className="text-2xl mb-8">
        {t("SubscriptionPage:donate")} {t("SubscriptionPage:alipay_only")}
      </p>
      <p className="text-xl mb-3 md:mb-[60px] lg:mb-[60px] text-center">
        {t("SubscriptionPage:help_us_donate")}
      </p>
      <p className="text-lg sm:mb-3 md:mb-[20px] lg:mb-[20px] ">
        {t("SubscriptionPage:custom_amount")}
        {/*        &nbsp; &nbsp;
        {t("SubscriptionPage:alipay_only")} */}
      </p>
      <div className="flex border border-gray-600 rounded overflow-hidden">
        <button
          className="bg-gray-700 text-white w-1/4 h-12 hover:bg-gray-600 focus:outline-none" // 调整了高度为 h-12
          onClick={(e) => {
            e.stopPropagation();
            handleDecrement(e);
          }}
        >
          -
        </button>
        <input
          type="number"
          value={donationAmount}
          onChange={onDonationAmountChange}
          className="w-1/2 text-center bg-gray-700 text-white focus:outline-none h-12" // 调整了高度为 h-12
          onClick={(e) => e.stopPropagation()}
        />
        <button
          className="bg-gray-700 text-white w-1/4 h-12 hover:bg-gray-600 focus:outline-none" // 调整了高度为 h-12
          onClick={(e) => {
            e.stopPropagation();
            handleIncrement(e);
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

const SubscriptionPage = () => {
  const { t } = useTranslation();
  const [exchangeRate, setExchangeRate] = useState(7); // Default value
  const [donationAmount, setDonationAmount] = useState("10"); // 新增状态变量

  const [orderData, setOrderData] = useState({
    outTradeNo: "", // 从后端生成的订单号
    amount: (20 * exchangeRate).toFixed(2), // 假设初始金额是20美元，转换为人民币
    subject: "BasicSubscription",
    body: "BasicSubscription",
  });

  // 当 exchangeRate 更新时，更新 orderData 中的 amount
  useEffect(() => {
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      amount: (20 * exchangeRate).toFixed(2), // 使用新汇率
    }));
  }, [exchangeRate]); // 依赖于 exchangeRate

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/USD"
        );
        // Assuming the API returns a JSON object with rates and you need USD to CNY rate
        const rate = response.data.rates.CNY;
        setExchangeRate(rate);
      } catch (error) {
        //console.error("Error fetching exchange rate:", error);
        // Handle error or set a default exchange rate
      }
    };

    fetchExchangeRate();
  }, []); // Empty dependency array means this effect runs once on component mount

  const handlePayment = async () => {
    // 在支付之前更新订单数据，确保使用最新的金额
    const updatedOrderData = {
      ...orderData,
      amount: selectedPlan === "free" ? donationAmount : orderData.amount,
    };

    try {
      // 发起支付请求
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/create-payment",
        updatedOrderData, // 使用最新的订单数据
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`, // 确保这里的token是有效的
          },
        }
      );

      const paymentUrl = response.data;
      // 如果有 URL，则在新窗口中打开
      if (paymentUrl) {
        window.open(paymentUrl, "_blank");
      }
    } catch (error) {
      //console.error("支付请求失败:", error);
      // 这里可以添加额外的错误处理逻辑
    }
  };

  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("basic");

  // 使用 useEffect 钩子记录 selectedPlan 及其变化
  useEffect(() => {
    //console.log("选择的计划:", selectedPlan);
  }, [selectedPlan]); // 依赖数组中包含 selectedPlan

  useEffect(() => {
    if (paymentCompleted) {
      navigate("/"); // 支付成功后跳转到首页
    }
  }, [paymentCompleted, navigate]);

  const [paymentStatusIntervalId, setPaymentStatusIntervalId] = useState(null);
  const checkPaymentStatus = useCallback(async () => {
    try {
      const outTradeNo = orderData.outTradeNo; // 使用订单号来查询支付状态
      const response = await axios.get(`/check-payment-status/${outTradeNo}`);
      if (response.data.success) {
        setPaymentCompleted(true);
        clearInterval(paymentStatusIntervalId); // 使用正确的 interval ID 停止轮询
      }
    } catch (error) {
      //  //console.error("检查支付状态失败:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData.outTradeNo]); // 添加依赖项

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkPaymentStatus();
    }, 15000); // 每5秒检查一次

    setPaymentStatusIntervalId(intervalId);
    // 清理函数：组件卸载时清除定时器
    return () => clearInterval(intervalId);
  }, [checkPaymentStatus]); // 添加 checkPaymentStatus 作为依赖

  const handleDonationAmountChange = (e) => {
    const newDonationAmount = e.target.value;
    setDonationAmount(newDonationAmount);

    // 如果当前选中的计划是捐赠（"free"），则同时更新订单数据
    if (selectedPlan === "free") {
      setOrderData((prevOrderData) => ({
        ...prevOrderData,
        amount: newDonationAmount, // 更新捐赠金额
        subject: "捐赠", // 可根据实际情况调整
        body: "用户捐赠", // 可根据实际情况调整
      }));
    }
  };

  const handlePlanChange = (newPlan) => {
    setSelectedPlan(newPlan);

    // 根据所选计划更新金额、subject和body
    let updatedAmount;
    let updatedSubject;
    let updatedBody;

    switch (newPlan) {
      case "basic":
        updatedAmount = (20 * exchangeRate).toFixed(2); // “基础订阅”价格乘以汇率
        updatedSubject = "BasicSubscription";
        updatedBody = "BasicSubscription";
        break;
      case "premium":
        updatedAmount = (30 * exchangeRate).toFixed(2); // “高级订阅”价格乘以汇率
        updatedSubject = "AdvancedSubscription";
        updatedBody = "AdvancedSubscription";
        break;
      case "basicYear":
        updatedAmount = (120 * exchangeRate).toFixed(2); // “基础订阅”价格乘以汇率
        updatedSubject = "BasicSubscriptionYearly";
        updatedBody = "BasicSubscriptionYearly";
        break;
      case "premiumYear":
        updatedAmount = (180 * exchangeRate).toFixed(2); // “高级订阅”价格乘以汇率
        updatedSubject = "AdvancedSubscriptionYearly";
        updatedBody = "AdvancedSubscriptionYearly";
        break;
      case "free":
        updatedAmount = donationAmount; // 使用用户输入的捐赠金额
        updatedSubject = "捐赠";
        updatedBody = "用户捐赠";
        break;
      default:
        // 可以处理未知计划的情况或保留原始状态
        return;
    }

    // 更新订单数据
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      amount: updatedAmount,
      subject: updatedSubject,
      body: updatedBody,
    }));
  };

  const token = localStorage.getItem("token"); // 从本地存储中获取 token

  const CustomToastContent = () => (
    <div>
      <strong className="text-green-500">
        {t("SubscriptionPage:activation_success")}
      </strong>
      <p>{t("SubscriptionPage:paypal_thank_you")}</p>
    </div>
  );

  const AlipayToastContent = () => (
    <div>
      <strong className="text-green-500">
        {t("SubscriptionPage:activation_success")}
      </strong>
      <p>{t("SubscriptionPage:alipay_thank_you")}</p>
    </div>
  );

  const [isLoading, setIsLoading] = useState(false); // 添加 isLoading 状态

  useEffect(() => {
    // 初始化 SSE 连接
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/events`
    );

    eventSource.onopen = (event) => {
      //console.log("SSE 连接已开启");
    };

    eventSource.onerror = (error) => {
      //console.error("SSE 连接错误:", error);
    };

    // 监听正在支付
    eventSource.addEventListener("paymentSalePending", () => {
      setIsLoading(true); // 当支付正在处理时，显示加载动画
    });
    // 订阅已激活事件
    eventSource.addEventListener("subscriptionActivated", () => {
      navigate("/payment-success");
      setIsLoading(false); // 隐藏加载动画
      toast.success(<CustomToastContent />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    // 处理其他相关事件
    eventSource.addEventListener("paymentFailed", (event) => {
      setIsLoading(false); // 隐藏加载动画
      toast.error("Payment failed, please try another payment method.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });
    eventSource.addEventListener("AlipaysubscriptionActivated", () => {
      setIsLoading(false); // 隐藏加载动画
      toast.success(<AlipayToastContent />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    eventSource.addEventListener("paymentDenied", (event) => {
      setIsLoading(false); // 隐藏加载动画
      toast.error("Payment denied, please try another payment method.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    // PayPal Webhooks事件处理
    eventSource.addEventListener("paymentCanceled", (event) => {
      setIsLoading(false); // 隐藏加载动画
      //console.log("处理支付取消事件:", event);
      // 在这里执行支付取消的逻辑
      toast.info("Payment has been canceled.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    eventSource.addEventListener("paymentExpired", (event) => {
      setIsLoading(false); // 隐藏加载动画
      //console.log("处理支付过期事件:", event);
      // 在这里执行支付过期的逻辑
      toast.info("Payment has expired, please try again.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    eventSource.addEventListener("paymentPending", (event) => {
      setIsLoading(false); // 隐藏加载动画
      //console.log("处理支付等待事件:", event);
      // 在这里执行支付等待的逻辑
      toast.info("Payment is pending, please check the status later.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
    });

    // 清理函数
    return () => {
      eventSource.close();
      //console.log("SSE 连接已关闭");
    };
  });

  const handlePaypalButtonClick = () => {
    if (token) {
      // 如果存在 token，表示用户已登录，执行正常操作
      return (
        <div>
          <div
            onClick={handlePayment}
            className="p-4 my-4 rounded border border-transparent w-full box-border bg-mblue text-white cursor-pointer text-center"
          >
            <div
              style={{
                color: "#ffffff",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 18,
              }}
            >
              {t("SubscriptionPage:alipay")}
            </div>
          </div>
          <PaypalButton selectedPlan={selectedPlan} />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            navigate("/login");
          }}
          className="p-4 my-4 rounded  w-full box-border bg-mblue sm:bg-G text-white cursor-pointer text-center"
        >
          {t("SubscriptionPage:buy")}
        </div>
      );
    }
  };

  const handleAlipayButtonClick = () => {
    if (token) {
      // 如果存在 token，表示用户已登录，执行正常操作
      return (
        <div>
          <div
            onClick={handlePayment}
            className="p-4 my-4 rounded border border-transparent w-full box-border bg-mblue text-white cursor-pointer text-center"
          >
            <div
              style={{
                color: "#ffffff",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 18,
              }}
            >
              {t("SubscriptionPage:alipay")}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            navigate("/login");
          }}
          className="p-4 my-4 rounded  w-full box-border bg-mblue sm:bg-G text-white cursor-pointer text-center"
        >
          {t("SubscriptionPage:buy")}
        </div>
      );
    }
  };

  const handleIncrement = () => {
    const newAmount = Math.max(0, Number(donationAmount) + 1).toString();
    setDonationAmount(newAmount);

    if (selectedPlan === "free") {
      setOrderData({
        ...orderData,
        amount: newAmount, // 使用增加后的捐赠金额
      });
    }
  };

  const handleDecrement = () => {
    const newAmount = Math.max(0, Number(donationAmount) - 1).toString();
    setDonationAmount(newAmount);

    if (selectedPlan === "free") {
      setOrderData({
        ...orderData,
        amount: newAmount, // 使用减少后的捐赠金额
      });
    }
  };

  // 加载提示组件
  const LoadingIndicator = () => (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#2c2e2f] border border-gray-500 rounded-lg p-[36px] shadow-xl z-50">
      <div className="flex items-center space-x-4">
        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
        <p className="text-xl font-semibold text-white">
          Processing Payment, Please Wait...
        </p>
      </div>
    </div>
  );

  const [view, setView] = useState("monthly"); // 新增状态用于跟踪当前视图

  const handleViewChange = (newView) => {
    setView(newView);
    if (newView === "donation") {
      setSelectedPlan("free");
    }
  };

  // 根据所选视图计算胶囊背景位置
  const sliderVariants = {
    monthly: { x: "-100%" },
    annual: { x: "0%" },
    donation: { x: "100%" },
  };
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Subscription Plans - 订阅计划| New Horizon</title>
          <meta
            name="description"
            content="选择新长慧和科技的订阅计划，享受定制化的金融分析工具和专业服务。Choose your subscription plan with New Horizon to enjoy customized financial analysis tools and professional services."
          />
          <meta
            name="keywords"
            content="订阅, 金融服务, 新长慧和科技, 量化分析, Subscription, Financial Services, New Horizon, Quantitative Analysis"
          />
          <meta
            property="og:title"
            content="Subscription Plans - 订阅计划| New Horizon"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://nhwt.tech/subscribe" />
          <link rel="canonical" href="https://nhwt.tech/subscribe" />
        </Helmet>
        <style>
          {`
          input[type="number"]::-webkit-inner-spin-button, 
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
        </style>

        <Header />
        <ToastContainer />
        {isLoading && <LoadingIndicator />}

        <div className="flex justify-center items-start min-h-[1100px]">
          <div className="font-arial relative top-[20px] w-[700px] m-[20px] p-[20px] border border-[#434651] rounded-[5px] bg-[#1c1c1c] text-white">
            <div className="text-[24px] mb-5">
              {t("SubscriptionPage:select")}
            </div>

            <div
              style={{
                boxShadow: "inset 0 0 0 2px #434651", // 向内的边框效果
              }}
              className="flex h-10 items-center justify-center mb-8 relative rounded-full w-[100%]"
            >
              <motion.div
                className="absolute  border border-blue-500 bg-gray-800 rounded-full h-full "
                style={{ width: "33.33%", bottom: 0 }} // 调整胶囊宽度和位置
                variants={sliderVariants}
                animate={view}
                transition={{ type: "tween", duration: 0.3 }} // 平滑过渡
              />
              <div className="flex w-full justify-between">
                <div
                  className="w-1/3 text-center z-10 relative"
                  onClick={() => handleViewChange("monthly")}
                >
                  <span className="text-white ">
                    {t("SubscriptionPage:monthlyPayment")}
                  </span>
                  <span className="absolute top-0 right-8 transform sm:translate-x-[58px] md:translate-x-1/2 lg:translate-x-1/2 sm:-translate-y-[22px] md:-translate-y-1/2 lg:-translate-y-1/2 bg-mgreen  md:bg-G sm:bg-G text-white px-2 py-1 sm:w-[80px] sm:text-[10px] md:text-xs lg:text-xs font-bold rounded-full">
                    Try for free
                  </span>
                </div>
                <div
                  className="w-1/3 text-center z-10 relative"
                  onClick={() => handleViewChange("annual")}
                >
                  <span className="text-white">
                    {t("SubscriptionPage:annualPayment")}
                  </span>
                  <span className="absolute top-0 right-8 transform sm:translate-x-[58px] md:translate-x-1/2 lg:translate-x-1/2 sm:-translate-y-[22px] md:-translate-y-1/2 lg:-translate-y-1/2 bg-mgreen  md:bg-G sm:bg-G text-white px-2 py-1 sm:w-[80px] sm:text-[10px] md:text-xs lg:text-xs font-bold rounded-full">
                    50% off!
                  </span>
                </div>
                <div
                  className="w-1/3 text-center z-10"
                  onClick={() => handleViewChange("donation")}
                >
                  <span className="text-white">
                    {t("SubscriptionPage:donation")}
                  </span>
                </div>
              </div>
            </div>

            {view === "monthly" && (
              <div>
                <div className="lg:flex gap-4 md:flex gap-4 mb-15">
                  {/* 按月付费视图的内容 */}

                  <Card
                    plan="basic"
                    title={t("SubscriptionPage:basic")}
                    price={t("SubscriptionPage:basic_price")}
                    features={[
                      t("SubscriptionPage:backtesting"),
                      t("SubscriptionPage:efficiency"),
                      t("SubscriptionPage:price"),
                    ]}
                    selectedPlan={selectedPlan}
                    onSelectPlan={handlePlanChange}
                  />
                  <Card
                    plan="premium"
                    title={t("SubscriptionPage:advanced")}
                    price={t("SubscriptionPage:advanced_price")}
                    features={[
                      t("SubscriptionPage:basic_desc"),
                      t("SubscriptionPage:signals"),
                      t("SubscriptionPage:indicators"),
                      t("SubscriptionPage:insights"),
                      t("SubscriptionPage:decision_support"),
                    ]}
                    selectedPlan={selectedPlan}
                    onSelectPlan={handlePlanChange}
                  />
                </div>
                {handlePaypalButtonClick()}
              </div>
            )}
            {view === "annual" && (
              <div>
                <div className="lg:flex gap-4 md:flex gap-4 mb-15">
                  {/* 按年付费视图的内容 */}
                  <Card
                    plan="basicYear"
                    title={t("SubscriptionPage:basic")}
                    price={t("SubscriptionPage:basic_price_year")}
                    originalPrice={t("SubscriptionPage:original_basic_price")}
                    features={[
                      t("SubscriptionPage:backtesting"),
                      t("SubscriptionPage:efficiency"),
                      t("SubscriptionPage:price"),
                    ]}
                    selectedPlan={selectedPlan}
                    onSelectPlan={handlePlanChange}
                  />
                  <Card
                    plan="premiumYear"
                    title={t("SubscriptionPage:advanced")}
                    price={t("SubscriptionPage:advanced_price_year")}
                    originalPrice={t(
                      "SubscriptionPage:original_advanced_price"
                    )}
                    features={[
                      t("SubscriptionPage:basic_desc"),
                      t("SubscriptionPage:signals"),
                      t("SubscriptionPage:indicators"),
                      t("SubscriptionPage:insights"),
                      t("SubscriptionPage:decision_support"),
                    ]}
                    selectedPlan={selectedPlan}
                    onSelectPlan={handlePlanChange}
                  />
                </div>
                {handlePaypalButtonClick()}
              </div>
            )}
            {view === "donation" && (
              <div>
                <div className="lg:flex gap-4 md:flex gap-4 mb-15 h-[400px] ">
                  <DonationCard
                    selectedPlan={selectedPlan}
                    onSelectPlan={setSelectedPlan}
                    donationAmount={donationAmount}
                    onDonationAmountChange={handleDonationAmountChange}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                  />
                </div>
                {handleAlipayButtonClick()}
              </div>
            )}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default SubscriptionPage;
